import React, { useMemo, useRef } from 'react';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { useEventEmitterListener } from 'hooks/useEventEmitterListener';
import { Color } from 'types/color.type';
import cloneDeep from 'helpers/cloneDeep';
import { Guideline, LayoutGridDirectionOptions, LayoutGridOptions, LayoutGridUnitOptions } from 'components/template-designer/types/formatProperties.type';
import Format from 'components/template-designer/types/format.type';
import FrameType from 'components/template-designer/types/frameTypes.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import defaultLayoutGrid from 'components/template-designer/config/defaultLayoutGrid';
import { CanvasHelpers } from 'components/template-designer/helpers/canvas.helpers';
import { DEFAULT_ZOOM_LEVEL } from 'components/template-designer/constants';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import useLayoutGrid from '../../layer-edit/components/sections/layout-grid-section/hooks/use-layout-grid';
import '../styles/layoutGrid.scss';

interface Props {
    format: Format;
    frameType: FrameType['key'];
    layoutGrid: Guideline[];
}

const LayoutGrid = ({ format, frameType, layoutGrid }: Props) => {
    const formatLayoutGridModel = getTemplateData<LayoutGridOptions>(`layerProperties.${format.key}.${frameType}.properties.layoutGrid.activeLayout`);
    const generalLayoutGridModel = getTemplateData<LayoutGridOptions>(`layerProperties.general.${frameType}.properties.layoutGrid.activeLayout`);
    const layoutGridModel = formatLayoutGridModel || generalLayoutGridModel;
    const { changeLayoutGrid, layoutGrid: oldLayoutGrid } = useLayoutGrid(layoutGridModel, [format.key]);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const zoomLevel = useEventEmitterListener(EventEmitterTypes.TDzoomLevel) ?? DEFAULT_ZOOM_LEVEL;
    const currentFormat = document.querySelector(`.template-designer__format #${format.key}`);

    const LAYOUTGRID_FORMAT_DRAGGING_CLASS = 'template-designer__format--dragging-layout-grid';
    const LAYOUTGRID_LINE_WIDTH = 1;
    const LAYOUTGRID_LINE_PADDING = 2;

    const correctedLineWidth = CanvasHelpers.scaleValueWithZoom(LAYOUTGRID_LINE_WIDTH);

    // Get layoutGridcolor
    const { generalLayoutGridColor, formatSpecificLayoutGridColor } = useComponentStore<{
        generalLayoutGridColor: Color | undefined;
        formatSpecificLayoutGridColor: Color | undefined;
    }>('TemplateDesigner', {
        fields: {
            generalLayoutGridColor: `layerProperties.general.${frameType}.properties.layoutGrid.color`,
            formatSpecificLayoutGridColor: `layerProperties.${format.key}.${frameType}.properties.layoutGrid.color`
        }
    });
    const layoutGridColor: Color = formatSpecificLayoutGridColor || generalLayoutGridColor || defaultLayoutGrid['color'];

    /**
     * Converts percentage value to pixel value based on the format size
     * @param value value in percentages
     * @param direction direction of the layoutGrid
     * @param format format info
     * @returns value in pixel based on the format size
     */
    const percentToPixel = (value: number, direction: LayoutGridDirectionOptions, format: Format) => {
        const totalSize = ['left', 'right'].includes(direction) ? format.width : format.height;
        return (value / 100) * totalSize;
    };

    /**
     * Converts pixel value to percent value based on the format size
     * @param value value in pixels
     * @param direction direction of the layoutGrid
     * @param format format info
     * @returns value in percentage based on the format size
     */
    const pixelToPercent = (value: number, direction: LayoutGridDirectionOptions, format: Format) => {
        const totalSize = ['left', 'right'].includes(direction) ? format.width : format.height;
        return (value / totalSize) * 100;
    };

    /**
     * Handles the mouse down event on the layout grid line
     * @param e Mouse down event
     * @param id Id of the layout grid line
     * @param direction Direction of the layout grid line
     */
    const handleMouseDown = (e, id, direction) => {
        e.preventDefault();
        e.stopPropagation();
        const div = e.currentTarget;
        const initialMousePosition = { x: e.clientX, y: e.clientY };
        const initialDivPosition = {
            left: div.offsetLeft,
            right: format.width - div.offsetLeft,
            top: div.offsetTop,
            bottom: format.height - div.offsetTop
        };
        const endPosition = {
            left: div.offsetLeft,
            right: format.width - div.offsetRight,
            top: div.offsetTop,
            bottom: format.height - div.offsetTop
        };
        const formatDragClassName = LAYOUTGRID_FORMAT_DRAGGING_CLASS + `-${direction === 'left' || direction === 'right' ? 'vertical' : 'horizontal'}`;

        /**
         * Handles the mouse move event on the layout grid line. Moving the line directly in the dom.
         * @param e Mouse move event
         */
        const handleMouseMove = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            //Add class to reflect dragging of the grid on the cursor
            if (currentFormat) {
                currentFormat.classList.add(formatDragClassName);
            }
            if (direction === 'left') {
                const deltaX = e.clientX - initialMousePosition.x;
                let newLeft = initialDivPosition.left + CanvasHelpers.scaleValueWithZoom(deltaX);
                //Cap the values so the line can not be dragged outside of the canvas
                if (newLeft > format.width) newLeft = format.width - correctedLineWidth - LAYOUTGRID_LINE_PADDING;
                if (newLeft < -LAYOUTGRID_LINE_PADDING) newLeft = -LAYOUTGRID_LINE_PADDING;

                div.style.left = `${newLeft}px`;
                endPosition.left = newLeft;
            } else if (direction === 'right') {
                const deltaX = e.clientX - initialMousePosition.x;
                let newLeft = format.width - (initialDivPosition.right - CanvasHelpers.scaleValueWithZoom(deltaX));
                if (newLeft > format.width) newLeft = format.width - correctedLineWidth - LAYOUTGRID_LINE_PADDING;
                if (newLeft < -LAYOUTGRID_LINE_PADDING) newLeft = -LAYOUTGRID_LINE_PADDING;

                div.style.left = `${newLeft}px`;
                endPosition.right = format.width - newLeft;
            } else if (direction === 'top') {
                const deltaY = e.clientY - initialMousePosition.y;
                let newTop = initialDivPosition.top + CanvasHelpers.scaleValueWithZoom(deltaY);
                if (newTop > format.height) newTop = format.height - correctedLineWidth - LAYOUTGRID_LINE_PADDING;
                if (newTop < -LAYOUTGRID_LINE_PADDING) newTop = -LAYOUTGRID_LINE_PADDING;

                div.style.top = `${newTop}px`;
                endPosition.top = newTop;
            } else if (direction === 'bottom') {
                const deltaY = e.clientY - initialMousePosition.y;
                let newTop = format.height - (initialDivPosition.bottom - CanvasHelpers.scaleValueWithZoom(deltaY));
                if (newTop > format.height) newTop = format.height - correctedLineWidth - LAYOUTGRID_LINE_PADDING;
                if (newTop < -LAYOUTGRID_LINE_PADDING) newTop = -LAYOUTGRID_LINE_PADDING;

                div.style.top = `${newTop}px`;
                endPosition.bottom = format.height - newTop;
            }
        };

        /**
         * Handles the mouse up event on the layout grid line. Saving the new position of the layout grid line.
         * @param e Mouse up event
         */
        const handleMouseUp = (e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const newLayoutGrid = cloneDeep(oldLayoutGrid);

            const newLineIndex = newLayoutGrid.findIndex((layoutGridLine) => layoutGridLine.id === id);
            //There is padding on the layout grid line to make it easier to drag, this padding needs to be corrected
            const linePaddingCorrection =
                direction === 'top' || direction === 'left'
                    ? LAYOUTGRID_LINE_PADDING + correctedLineWidth / 2
                    : -LAYOUTGRID_LINE_PADDING - correctedLineWidth / 2;

            if (newLayoutGrid[newLineIndex].value.unit === LayoutGridUnitOptions.Px) {
                newLayoutGrid[newLineIndex].value.value = Math.round(endPosition[direction] + linePaddingCorrection);
            } else {
                newLayoutGrid[newLineIndex].value.value = Math.round(pixelToPercent(endPosition[direction] + linePaddingCorrection, direction, format));
            }

            changeLayoutGrid(newLayoutGrid, [format.key]);
            TemplateDesignerStore.save(
                [
                    ['state.selectedFormats', [format.key]],
                    ['state.selectedLayers', []],
                    ['view.showTab', 'layerEdit']
                ],
                { saveToHistory: false }
            );
            if (currentFormat) {
                currentFormat.classList.remove(formatDragClassName);
            }
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    /**
     * Creates the layout grid divs based on the layout grid data
     */
    const layoutGridDivs = useMemo(() => {
        return layoutGrid.map((layoutGridLine) => {
            const { value, direction } = layoutGridLine;

            const valueInPixels = (() => {
                const newValue = (() => {
                    if (value.unit === LayoutGridUnitOptions.Px) return value.value;
                    return percentToPixel(value.value, direction, format);
                })();

                let valueInPixels = 0;
                if (direction === 'left') {
                    valueInPixels = newValue;
                } else if (direction === 'right') {
                    valueInPixels = format.width - newValue;
                } else if (direction === 'top') {
                    valueInPixels = newValue;
                } else {
                    valueInPixels = format.height - newValue;
                }

                return valueInPixels;
            })();

            const cursor = (() => {
                if (layoutGridModel !== LayoutGridOptions.Guidelines) return 'initial';
                return layoutGridLine.direction === 'left' || layoutGridLine.direction === 'right' ? 'col-resize' : 'row-resize';
            })();

            return (
                <div
                    key={layoutGridLine.id}
                    style={{
                        userSelect: 'none',
                        position: 'absolute',
                        cursor: cursor,
                        pointerEvents: 'auto',
                        left: direction === 'left' || direction === 'right' ? `${valueInPixels - LAYOUTGRID_LINE_PADDING - correctedLineWidth / 2}px` : '0px',
                        top: direction === 'top' || direction === 'bottom' ? `${valueInPixels - LAYOUTGRID_LINE_PADDING - correctedLineWidth / 2}px` : '0px',
                        padding:
                            layoutGridLine.direction === 'top' || layoutGridLine.direction === 'bottom'
                                ? `${LAYOUTGRID_LINE_PADDING}px 0`
                                : `0 ${LAYOUTGRID_LINE_PADDING}px`,
                        width: layoutGridLine.direction === 'top' || layoutGridLine.direction === 'bottom' ? '100%' : ``,
                        height: layoutGridLine.direction === 'left' || layoutGridLine.direction === 'right' ? '100%' : ``
                    }}
                    onMouseDown={(e) => layoutGridModel === LayoutGridOptions.Guidelines && handleMouseDown(e, layoutGridLine.id, layoutGridLine.direction)}>
                    <div
                        style={{
                            backgroundColor: layoutGridColor.points[0].color.hex,
                            width: layoutGridLine.direction === 'top' || layoutGridLine.direction === 'bottom' ? '100%' : `${correctedLineWidth}px`,
                            height: layoutGridLine.direction === 'left' || layoutGridLine.direction === 'right' ? '100%' : `${correctedLineWidth}px`
                        }}></div>
                </div>
            );
        });
    }, [canvasRef.current, layoutGrid, zoomLevel]);

    return <div className="template-designer__layout-grid">{layoutGridDivs}</div>;
};

export default LayoutGrid;
